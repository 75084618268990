import ApiService from './api.service'

import localforage from 'localforage'

import store from '../store'

const ManualService = {

  async getManual (year) {
    const isOnline = store.getters['global/isOnline']
    if (isOnline) {
      const response = await ApiService.get(`catalog/year/${year}`)
      return localforage.setItem('activemanual', response)
        .then(() => {
          store.commit('manual/saveManual', response)
          return response
        })
    } else {
      const response = await localforage.getItem('activemanual')
      if (response) {
        store.commit('manual/saveManual', response)
      }
      return response
    }
  }

}

export default ManualService
