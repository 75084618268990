<template>
  <div class="is-flex is-justify-content-space-between has-text-grey py-3">
    <small>
      {{ appName }} - v{{ version }}
    </small>
    <small>
      &copy; {{ year }}
    </small>
  </div>
</template>

<script>
import { version } from '../../package.json'

export default {
  data () {
    return {
      appName: process.env.VUE_APP_TITLE,
      year: this.$dayjs().format('YYYY'),
      version
    }
  }
}
</script>
