import Vue from 'vue'
import Router from 'vue-router'
import mainAuth from './auth-async'

import Welcome from './views/Welcome.vue'
import Home from './views/Home.vue'
import LoggedOut from './views/auth/LoggedOut.vue'

import authorized from './middleware/authorized'

import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Welcome',
      component: Welcome,
      meta: {
        layout: 'Unauthorized'
      }
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        title: 'Home',
        authName: mainAuth.authName,
        middleware: authorized
      }
    },
    {
      path: '/manual/:cat?',
      name: 'Manual',
      component: () => import(/* webpackChunkName: "manual" */ './views/Manual.vue'),
      meta: {
        title: 'Handbuch',
        authName: mainAuth.authName,
        middleware: authorized
      }
    },
    {
      path: '/audits',
      name: 'Audits',
      component: () => import(/* webpackChunkName: "audits" */ './views/audits'),
      meta: {
        title: 'Audits',
        authName: mainAuth.authName,
        middleware: authorized
      }
    },
    {
      path: '/audits/:id/edit',
      name: 'AuditEdit',
      component: () => import(/* webpackChunkName: "auditedit" */ './views/audits/Edit.vue'),
      meta: {
        title: 'Audit Edit',
        authName: mainAuth.authName,
        middleware: authorized
      }
    },
    {
      path: '/audits/:id/summary',
      name: 'AuditSummary',
      component: () => import(/* webpackChunkName: "auditsummary" */ './views/audits/summary'),
      meta: {
        title: 'Audit Summary',
        authName: mainAuth.authName,
        middleware: authorized
      },
      children: [
        {
          path: 'topics/:topicId',
          name: 'AuditTopic',
          component: () => import(/* webpackChunkName: "audittopic" */ './views/audits/summary/topics'),
          meta: {
            title: 'Audit Topic',
            authName: mainAuth.authName,
            middleware: authorized
          }
        }
      ]
    },
    {
      path: '/help',
      name: 'Help',
      component: () => import(/* webpackChunkName: "help" */ './views/Help.vue'),
      meta: {
        title: 'Legende',
        authName: mainAuth.authName,
        middleware: authorized
      }
    },
    {
      path: '/how-to',
      name: 'Howto',
      component: () => import(/* webpackChunkName: "howto" */ './views/Howto.vue'),
      meta: {
        title: 'Anleitungen',
        authName: mainAuth.authName,
        middleware: authorized
      }
    },
    {
      path: '/auth/logged-out',
      name: 'LoggedOut',
      component: LoggedOut,
      meta: {
        layout: 'Unauthorized'
      }
    },
    {
      path: '/auth/unauthorized',
      name: 'Unauthorized',
      component: () => import(/* webpackChunkName: "unauthorized" */ './views/auth/Unauthorized.vue'),
      meta: {
        layout: 'Unauthorized'
      }
    }
  ],
  linkActiveClass: 'is-active'
})

mainAuth.useRouter(router)

function nextFactory (context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next
  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    const context = { from, next, router, to }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  }
  return next()
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    store.commit('global/setPageTitle', { title: to.meta.title, documentTitle: to.meta.title })
  } else {
    store.commit('global/setPageTitle', { title: process.env.VUE_APP_TITLE })
  }
})

export default router
