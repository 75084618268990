import Vue from 'vue'
import axios from 'axios'

const ApiService = {
  init (baseURL) {
    axios.defaults.baseURL = `${baseURL}/`
  },

  setToken (token) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token
  },

  async get (resource, config) {
    this.setToken(Vue.prototype.$oidc.accessToken)
    try {
      const response = await axios(resource, config)
      return response.data
    } catch (error) {
      return new Promise((resolve, reject) => {
        return reject(error)
      })
    }
  },

  async post (resource, data) {
    try {
      const response = await axios.post(resource, data)
      return response.data
    } catch (error) {
      return Promise.reject(new Error(error))
    }
  },

  async patch (resource, data) {
    try {
      const response = await axios.patch(resource, data, {
        headers: {
          'Content-Type': 'application/json-patch+json'
        }
      })
      return response.data
    } catch (error) {
      return Promise.reject(new Error(error))
    }
  },

  async put (resource, data) {
    try {
      const response = await axios.put(resource, data)
      return response.data
    } catch (error) {
      return Promise.reject(new Error(error))
    }
  },

  async delete (resource) {
    try {
      const response = await axios.delete(resource)
      return response.data
    } catch (error) {
      return Promise.reject(new Error(error))
    }
  },

  async getAuthorizedFile (resource, fileName = null) {
    try {
      const response = await axios.get(resource, { responseType: 'blob' })
      var contentDisposition = response.headers['content-disposition']
      fileName = fileName || contentDisposition.split('filename=')[1].split(';')[0] || 'file'
      fileName = fileName.replace(/['"]/g, '')
      var fileURL = window.URL.createObjectURL(new Blob([response.data]))
      var fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)
      document.body.appendChild(fileLink)
      fileLink.click()
    } catch (error) {
      return Promise.reject(new Error(error))
    }
  },

  uploadAuthorizedFile (resource, file, updateProgress) {
    const formData = new FormData()
    formData.append('file', file)
    return axios.post(resource, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        let percentComplete = progressEvent.loaded / progressEvent.total
        percentComplete = parseInt(percentComplete * 100)
        updateProgress(percentComplete)
      }
    })
      .then(response => response.data)
      .catch(error => Promise.reject(new Error(error)))
  }

}

export default ApiService
