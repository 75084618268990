<template>
  <div class="is-flex is-align-items-center">
    <b-tooltip position="is-left" label="Sich als anderen User ausgeben">
      <b-button icon-left="user-circle" size="is-small" @click="showModal = true" :disabled="!isOnline">Impersonate</b-button>
    </b-tooltip>
    <div v-if="impersonated" class="is-flex is-align-items-center ml-2">
      <span>{{ impersonated.givenName }} {{ impersonated.familyName }}</span>
      <div @click="resetUser" class="is-clickable">
        <b-icon class="mt-1" icon="times" custom-size="sm" />
      </div>
    </div>

    <Modal v-if="showModal">
      <template #header>
        <h1 class="is-size-5 has-text-weight-bold">User finden</h1>
      </template>
      <template #body>
        Um die Daten eine jeweiligen Users zu sehen, den User finden und auswählen.<br>
        Danach können alle Audits des jeweiligen Users eingesehen werden.
        <b-autocomplete
          :data="data"
          :loading="isFetching"
          class="mt-4"
          clearable
          icon="search"
          placeholder="Username eingeben"
          @typing="getData"
          @select="option => user = option"
        >
          <template slot-scope="props">
            <div>
              {{ props.option.givenName }} {{ props.option.familyName }}
            </div>
          </template>
          <template #empty>Kein User wurde gefunden</template>
        </b-autocomplete>

        <div class="mt-3">
          <b>Ausgewählter User:</b> {{ user ?  `${user.givenName} ${user.familyName}` : ' -'  }}
        </div>
      </template>
      <template #footer>
        <div v-if="user">
          <b-button
            @click="resetUser"
            type="is-default"
            class="mr-2"
          >
            Zurücksetzen
          </b-button>
          <b-button
            @click="setUser"
            type="is-primary"
          >
            User auswählen
          </b-button>
        </div>
        <b-button
          v-if="!user"
          @click="showModal = false"
          type="is-default"
        >
          Schliessen
        </b-button>
      </template>

    </Modal>

  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { debounce as _debounce } from 'lodash'

import ApiService from '@/services/api.service'

import Modal from './Modal.vue'

export default {
  computed: {
    ...mapGetters({
      impersonated: 'auth/impersonated',
      isOnline: 'global/isOnline'
    })
  },
  components: {
    Modal
  },
  data () {
    return {
      user: null,
      showModal: false,
      data: [],
      isFetching: false
    }
  },
  methods: {
    ...mapMutations({
      setImpersonated: 'auth/setImpersonated'
    }),
    getData: _debounce(async function (q) {
      if (!q.length) {
        this.data = []
        return
      }
      this.isFetching = true
      const response = await ApiService.get(`users?term=${q}`)
      this.data = response.data
      this.isFetching = false
    }, 500),
    setUser () {
      this.setImpersonated(this.user)
      this.showModal = false
      if (['AuditEdit', 'AuditSummary', 'AuditTopic'].includes(this.$route.name)) {
        this.$router.replace({ name: 'Audits' })
      }
    },
    resetUser () {
      this.setImpersonated()
      this.user = null
      if (['AuditEdit', 'AuditSummary', 'AuditTopic'].includes(this.$route.name)) {
        this.$router.replace({ name: 'Audits' })
      }
    }
  }
}
</script>
