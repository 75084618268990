import ApiService from './api.service'

import localforage from 'localforage'

import store from '../store'
import { isArray } from 'lodash'

const AuditsService = {

  async getAudits (params) {
    const isOnline = store.getters['global/isOnline']
    if (isOnline) {
      const response = await ApiService.get(`audits${params}`)
      return response
    } else {
      const list = await localforage.getItem('audits')
      if (list) {
        return {
          data: list,
          total: list.length
        }
      }
      return null
    }
  },

  async getAudit (id, edit = false) {
    const isOnline = store.getters['global/isOnline']
    const localAudit = await this.getLocalAuditById(id)
    if (!localAudit && isOnline) {
      const response = await ApiService.get(`audits/${id}${edit ? '/edit' : ''}`)
      if (response.state === 'Running') {
        await this.addAuditToLocal(response)
      }
      store.commit('audits/setActiveAudit', response)
      return response
    } else {
      const lastSync = await localforage.getItem('lastsync')
      store.commit('global/setLastSync', lastSync)
      store.commit('audits/setActiveAudit', localAudit)
      return localAudit
    }
  },

  async getLocalAudits () {
    const audits = await localforage.getItem('audits')
    return audits || []
  },

  async getLocalAuditById (id) {
    const audits = await localforage.getItem('audits')
    return audits ? audits.find(e => e.id === parseInt(id)) : null
  },

  async addAuditToLocal (audit) {
    let audits = await localforage.getItem('audits')
    audits = audits ? audits.filter(e => e.id !== audit.id) : []
    audits.push(audit)
    return localforage.setItem('audits', audits)
  },

  async saveAudit (audit, setLAudit = true) {
    const isOnline = store.getters['global/isOnline']
    if (audit && setLAudit) {
      await this.setLocalAudit(audit)
    }
    if (isOnline && audit) {
      try {
        const response = await ApiService.post(`audits/${audit.id}`, audit.criteria.map(c => (
          {
            grade: c.grade,
            int: c.int,
            pub: c.pub,
            ci: c.ci,
            review: c.review
          }
        )))
        await this.updateLocalAuditCriteriaItems(audit, response)
        const d = new Date()
        await localforage.setItem('lastsync', d)
        store.commit('global/setLastSync', d)
        return Promise.resolve(true)
      } catch (error) {
        await this.removeLocalAudit(audit)
        return Promise.reject(new Error(error))
      }
    }
  },

  async updateLocalAuditCriteriaItems (audit, items) {
    if (items && isArray(items) && items.length) {
      const localAudit = await this.getLocalAuditById(audit.id)
      if (localAudit) {
        let criteria = localAudit.criteria
        criteria = criteria.filter(e => !items.find(f => f.ci === e.ci))
        criteria = [...criteria, ...items]
        localAudit.criteria = criteria
        store.commit('audits/updateLocalAuditCriteriaItems', items)
        await this.addAuditToLocal(localAudit)
      }
    }
  },

  async syncRunningAudits () {
    const audits = await localforage.getItem('audits')
    if (audits && audits.length) {
      store.commit('global/setSyncing', true)
      for (let index = 0; index < audits.length; index++) {
        const audit = audits[index]
        if (audit.state === 'Running') {
          await this.saveAudit(audit, false)
        }
      }
      store.commit('global/setSyncing', false)
      return Promise.resolve()
    }
  },

  async uploadFile (audit, file, updateProgress) {
    try {
      const response = await ApiService.uploadAuthorizedFile(`/audits/${audit.id}/report`, file, updateProgress)
      await this.setAuditReportId(audit, response.reportId)
    } catch (error) {
      return Promise.reject(new Error(error))
    }
  },

  async setAuditReportId (audit, reportId) {
    const localAudit = await this.getLocalAuditById(audit.id)
    if (localAudit) {
      localAudit.reportId = reportId
      await this.setLocalAudit(localAudit)
      store.commit('audits/setAuditReportId', reportId)
    }
  },

  async setLocalAudit (audit) {
    let audits = await localforage.getItem('audits')
    if (!audits) {
      audits = []
    }
    audits = audits.filter(e => e.id !== audit.id)
    audits.push(audit)
    await localforage.setItem('audits', audits)
  },

  async changeState (newState, audit) {
    const isOnline = store.getters['global/isOnline']
    if (isOnline) {
      await ApiService.post(`audits/${audit.id}/state`, { newState: newState })
    }
  },

  async removeLocalAudit (audit) {
    let audits = await localforage.getItem('audits')
    if (!audits) {
      audits = []
    }
    audits = audits.filter(e => e.id !== audit.id)
    await localforage.setItem('audits', audits)
  },

  calcTotalPercent (criteria = []) {
    const c = criteria.filter(e => e.grade < 10).map(e => this.gradeToPercent(e.grade))
    return parseInt(c.reduce((prev, curr) => prev + curr, 0) / c.length)
  },

  calcTopicPercent (criteria, tnr) {
    const topicCriteria = criteria.filter((e) => this.filterForTopic(e, tnr)).filter(e => e.grade < 10).map(e => this.gradeToPercent(e.grade))
    const grade = parseInt(topicCriteria.reduce((prev, curr) => prev + curr, 0) / topicCriteria.length)
    const allCannotBeGraded = criteria.filter((e) => this.filterForTopic(e, tnr)).length === criteria.filter((e) => this.filterForTopic(e, tnr)).filter(e => e.grade === 99).length
    return allCannotBeGraded ? 100 : grade || 0
  },

  filterForTopic (c, tnr) {
    const cr = store.getters['manual/criteria'].find(b => b.ci === c.ci)
    return cr ? cr.tnr === tnr : false
  },

  gradeToPercent (grade) {
    switch (grade) {
      case 2:
        return 60
      case 3:
        return 100
      default:
        return 0
    }
  },

  groupBy: key => array =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key]
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
      return objectsByKeyValue
    }, {})

}

export default AuditsService
