<template>
  <div id="app" class="app">
    <component v-if="$route.name !== 'signinwin-main'" :is="`${layoutType}Layout`">
      <router-view />
    </component>
    <router-view v-else />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import AuditService from '@/services/audits.service'

import UnauthorizedLayout from './layouts/UnauthorizedLayout.vue'
import AuthorizedLayout from './layouts/AuthorizedLayout.vue'

var isOnIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)
var eventName = isOnIOS ? 'pagehide' : 'beforeunload'

export default {
  components: {
    UnauthorizedLayout,
    AuthorizedLayout
  },
  data () {
    return {
      layoutType: 'Unauthorized'
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        this.layoutType = to.meta ? to.meta.layout || 'Authorized' : 'Authorized'
      }
    }
  },
  created () {
    window.addEventListener(eventName, this.beforeWindowUnload)
  },
  beforeDestroy () {
    window.removeEventListener(eventName, this.beforeWindowUnload)
  },
  mounted () {
    if (this.$oidc.isAuthenticated) {
      this.setUser(this.$oidc.user.profile)
      AuditService.syncRunningAudits()
    }
  },
  computed: {
    ...mapGetters({
      isOnline: 'global/isOnline'
    })
  },
  methods: {
    ...mapMutations({
      setUser: 'auth/setUser'
    }),
    confirmLeave () {
      return window.confirm('Achtung!\nWenn die Applikation verlassen wird, werden alle gespeicherten Daten gelöscht werden und nicht mit dem mit dem Server synchronisiert werden können.\nWenn die Änderungen gespeichert werden sollen, bitte erst schliessen nachdem eine Internetverbindung wieder aufgebaut werden konnte.')
    },
    confirmStay () {
      return !this.confirmLeave()
    },
    beforeWindowUnload (e) {
      if (this.isOnline) return
      if (this.confirmStay()) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
  }
}
</script>

<style lang="scss">
    @import './assets/styles/main.scss';
</style>
