<template>
  <div class="container">
    <section class="app-main-layout is-flex is-flex-direction-row">
      <div class="is-flex is-flex-direction-column is-flex-grow-1">
        <AppHeader />
        <div class="is-flex-grow-1 p-4 mb-4">
          <slot />
        </div>
        <AppFooter />
      </div>
    </section>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import AppFooter from '../components/AppFooter.vue'

export default {
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style lang='scss'>
.app-main-layout {
  position: absolute;
  width: 100%;
  min-height: 100%;
}
</style>
