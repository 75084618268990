// initial state
export const getDefaultState = () => {
  return {
    isOnline: navigator.onLine,
    showOfflineModal: false,
    pageTitle: {
      title: '',
      linkName: null,
      linkPath: ''
    },
    lastSync: null,
    syncing: false
  }
}
const state = getDefaultState()

const getters = {
  pageTitle (state) {
    return state.pageTitle
  },
  isOnline (state) {
    return state.isOnline
  },
  showOfflineModal (state) {
    return state.showOfflineModal
  },
  lastSync (state) {
    return state.lastSync
  },
  syncing (state) {
    return state.syncing
  }
}

const actions = {
  setShowOfflineModal ({ state }, s = true) {
    state.showOfflineModal = s
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setLastSync (state, lastSync) {
    state.lastSync = lastSync
  },
  setSyncing (state, syncing) {
    state.syncing = syncing
  },
  setIsOnline (state, isOnline) {
    state.isOnline = isOnline
  },
  setPageTitle (state, pageTitle) {
    if (!state.pageTitle || pageTitle.title !== state.pageTitle.title) {
      state.pageTitle = null
      setTimeout(() => {
        state.pageTitle = pageTitle
        if (pageTitle.documentTitle) {
          document.title = `${process.env.VUE_APP_TITLE} - ${pageTitle.documentTitle}`
        } else {
          document.title = process.env.VUE_APP_TITLE
        }
      }, 200)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
