import Vue from 'vue'
import { WebStorageStateStore } from 'oidc-client'
import { createOidcAuth, SignInType, LogLevel } from 'vue-oidc-client'

import ApiService from '@/services/api.service'
import UserService from './services/user.service'

const loco = window.location
const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`

const mainOidc = createOidcAuth(
  'main',
  SignInType.Window,
  appRootUrl,
  {
    authority: process.env.VUE_APP_ID_AUTHORITY,
    client_id: process.env.VUE_APP_ID_CLIENTID,
    response_type: process.env.VUE_APP_ID_RESPONSETYPE,
    scope: process.env.VUE_APP_ID_SCOPE,
    post_logout_redirect_uri: process.env.VUE_APP_ID_POST_LOGOUT_REDIRECT_URI,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    // automaticSilentRenew: false,
    accessTokenExpiringNotificationTime: 10
  },
  console,
  LogLevel.Debug
)

Vue.prototype.$oidc = mainOidc

mainOidc.$on('accessTokenExpiring', function () {
  // store.commit['']
})
mainOidc.$on('accessTokenExpired', function () {
  console.log('Token has Expired .......')
})
mainOidc.$on('userLoaded', function (user) {
  // setup axios with token in header
  ApiService.setToken(user.access_token)

  // add user to store and localforage
  UserService.setUser(user.profile)
})
mainOidc.$on('silentRenewError', function (err) {
  console.error('silent renew error', err)
})
mainOidc.$on('userSessionChanged', function () {
  console.log('user session changed')
})

export default mainOidc
