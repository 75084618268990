<template>
  <section class="box has-text-centered">
    <div class="mb-6">
      <h1 class="is-size-3 has-text-primary mb-4">
        TopQ - Das Qualitätsmanagementsystem von TopPharm
      </h1>
      <img src="@/assets/images/logos/LogoTopQ.png" alt="TopQ Logo">
    </div>
  </section>
</template>

<script>
import AuditService from '@/services/audits.service'

export default {
  mounted () {
    AuditService.syncRunningAudits()
  }
}
</script>
