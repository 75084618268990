import Vue from 'vue'
import Vuex from 'vuex'

import global from './global'
import manual from './manual'
import auth from './auth'
import audits from './audits'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,
    manual,
    auth,
    audits
  },
  actions: {
    resetState ({ commit }) {
      commit('global/resetState')
      commit('manual/resetState')
      commit('auth/resetState')
      commit('audit/resetState')
    }
  }
})
