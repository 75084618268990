export default {
  filters: {
    audtRatingTyp: function (value) {
      if (value > 69 && value <= 100) return 'is-success'
      if (value > 59) return 'is-warning'
      return 'is-danger'
    },
    audtCatDescr: function (value) {
      switch (value) {
        case 'S': return 'Selbstevaluation'
        case 'A': return 'Audit'
        case 'C': return 'Coaching'
        default: return '?'
      }
    }
  }
}
