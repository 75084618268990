// initial state
export const getDefaultState = () => {
  return {
    user: null,
    meta: null,
    impersonated: null
  }
}
const state = getDefaultState()

const getters = {
  user (state) {
    return state.user
  },
  hasRole: (state) => (role) => {
    return state.meta && state.meta.roles ? state.meta.roles.find(e => e === role) : false
  },
  hasPermission: () => (permission) => {
    return state.meta && state.meta.permissions ? state.meta.permissions.find(e => e === permission) : false
  },
  hasOneOfRoles: (state, getters) => (roles) => {
    let hasRole = false
    roles.forEach((r) => {
      if (getters.hasRole(r)) {
        hasRole = true
      }
    })
    return hasRole
  },
  metaKey: (state) => (key) => {
    return state.meta ? state.meta[key] : null
  },
  impersonated (state) {
    return state.impersonated
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setUser (state, user) {
    state.user = user
  },
  setMeta (state, meta) {
    state.meta = meta
  },
  setImpersonated (state, impersonated = null) {
    state.impersonated = impersonated
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
