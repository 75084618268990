<template>
  <div class="is-absolute unauthorized is-flex-1" :style="styleObject">
    <div class="unauthorized-layout is-flex is-flex-wrap">
      <div class="p-4 unauthorized-sidebar is-flex is-flex-direction-column has-text-white">
        <div class="is-flex-grow-1">
          <div class="is-flex is-justify-content-space-between">
            <div>
              {{ appTitle }}<br>
              TopPharm
            </div>
            <div class="is-hidden-tablet">
              <div>copyright &copy; {{ thisYear }}</div>
              <div class="has-text-right">v. {{ version }}</div>
            </div>
          </div>
        </div>
        <div class="is-hidden-mobile">
          <div>copyright &copy; {{ thisYear }}</div>
          <div>v{{ version }}</div>
        </div>
      </div>

      <div class="p-4 is-flex is-justify-content-center is-align-items-center is-flex-grow-1">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { version } from '../../package.json'

export default {
  data () {
    return {
      appTitle: process.env.VUE_APP_TITLE,
      thisYear: this.$dayjs().format('YYYY'),
      styleObject: {
        '--bgUrl': `url(${require('@/assets/images/bg-1.jpg')})`
      },
      version
    }
  }
}
</script>

<style lang='scss'>
@import '@/assets/styles/variables.scss';

.unauthorized,
.unauthorized-layout {
  min-height: 100vh;
}
.unauthorized::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;
  background-image: var(--bgUrl);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.unauthorized-layout {
  z-index: 2;
  position: relative;
}

.unauthorized-sidebar {
  background-color: $primary;
}

@media (max-width: $tablet) {
  .unauthorized-layout {
    flex-direction: column;

    .unauthorized-sidebar {
      width: 100%;
    }
  }
}
</style>
