import Vue from 'vue'

import Router from '../router'

import localforage from 'localforage'

import ApiService from './api.service'

import store from '../store'

const UserService = {

  async setUser (user) {
    const localUser = await localforage.getItem('user')
    if (localUser && parseInt(user.sub) !== parseInt(localUser.sub)) {
      await localforage.clear()
    }

    // set user
    await localforage.setItem('user', user)
    store.commit('auth/setUser', user)
  },

  async getUserMeta () {
    let meta = await localforage.getItem('usermeta')
    if (!meta) {
      try {
        meta = await ApiService.get('userprofile')
        await localforage.setItem('usermeta', meta)
      } catch (error) {
        return false
      }
    }
    store.commit('auth/setMeta', meta)
    return true
  },

  async signOut () {
    await localforage.clear()
    store.commit('resetState')
    if (store.getters['global/isOnline']) {
      Vue.prototype.$oidc.signOut()
    } else {
      Router.push({ name: 'LoggedOut' })
    }
  }
}

export default UserService
