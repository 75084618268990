<template>
  <div>
    <Modal v-if="showOfflineModal" @close="setShowOfflineModal(false)">
      <template #header>
        <h1 class="is-size-5 has-text-weight-bold">Keine Internet Verbindung !</h1>
      </template>
      <template #body>
        Es scheint keine Verbindung zum Internet zu bestehen.<br>
        <div v-if="audits.length">
          Folgende{{ audits.length === 1 ? 's' : '' }} Audit{{ audits.length > 1 ? 's können' : ' kann' }} trotzdem weitergeführt oder eingesehen werden:<br>
          <table class="table is-bordered is-striped is-narrow is-fullwidth my-3">
            <tbody>
              <tr v-for="audit in audits" :key="audit.id">
                <td>
                  <a @click.prevent="auditClicked(audit)">
                    {{ audit.store.id }} - {{ audit.store.name }} {{ audit.cat | audtCatDescr }} {{ audit.year }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          Um {{ audits.length === 1 ? 'es' : 'sie' }} abzuschliessen, muss eine Verbindung zum Internet bestehen.
        </div>
        <div v-else>
          Bitte eine Verbindung aufbauen um Audits einzusehen oder zu starten.
        </div>
      </template>
    </Modal>
    <div
      :class="[ isOnline ? '' : 'is-clickable' ]"
      @click="setShowModal"
      class="is-flex is-align-items-center"
    >
      <b-icon v-if="isOnline" icon="bolt" type="is-success" size="is-small" />
      <b-icon v-else icon="exclamation-circle" type="is-danger" size="is-small" />
      <span
        :class="[ isOnline ? 'has-text-success' : 'has-text-danger' ]"
        class="has-text-weight-semibold is-size-6 ml-1"
      >{{ isOnline ? 'online' : 'offline' }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import AuditService from '@/services/audits.service'

import Modal from './Modal.vue'

import filters from '@/mixins/filters'

export default {
  components: {
    Modal
  },
  mixins: [filters],
  data () {
    return {
      audits: []
    }
  },
  mounted () {
    window.addEventListener('online', this.setOnline)
    window.addEventListener('offline', this.setOnline)
    this.getLocalAudits()
  },
  beforeDestroy () {
    window.removeEventListener('online', this.setOnline)
    window.removeEventListener('offline', this.setOnline)
  },
  computed: {
    ...mapGetters({
      isOnline: 'global/isOnline',
      showOfflineModal: 'global/showOfflineModal'
    })
  },
  methods: {
    ...mapMutations({
      setIsOnline: 'global/setIsOnline'
    }),
    ...mapActions({
      setShowOfflineModal: 'global/setShowOfflineModal'
    }),
    async setOnline () {
      this.setIsOnline(navigator.onLine)
      if (navigator.onLine) {
        await AuditService.syncRunningAudits()
        if (!this.$oidc.user) {
          this.$router.push({ name: 'Home' })
        }
      } else {
        this.getLocalAudits()
      }
    },
    setShowModal () {
      if (!this.isOnline) {
        this.setShowOfflineModal()
      }
    },
    async getLocalAudits () {
      const audits = await AuditService.getLocalAudits()
      this.audits = audits
    },
    auditClicked (audit) {
      this.setShowOfflineModal(false)
      if (this.$route.name === 'AuditSummary' && this.$route.params.id === audit.id) {
        return
      }
      this.$router.push({ name: 'AuditSummary', params: { id: audit.id }, replace: this.$route.name === 'AuditSummary' })
    }
  }
}
</script>
