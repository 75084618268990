import 'vue-oidc-client/src/polyfill'

import Vue from 'vue'
import App from './App.vue'

import mainAuth from './auth-async'
import router from './router'

import localforage from 'localforage'
import store from './store'
import dayjs from 'dayjs'

import apiService from './services/api.service'

import { ConfigProgrammatic, Table, Sidebar, Menu, Icon, Button, Modal, Dialog, Navbar, Pagination, Progress, Field, Select, Input, Checkbox, Radio, Tooltip, Collapse, Upload, Toast, Autocomplete, Dropdown } from 'buefy'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTachometerAlt, faUser, faSignOutAlt, faTimes, faBars, faHome, faUserCircle, faArrowLeft, faArrowRight, faAngleLeft, faAngleRight, faArrowUp, faPlay, faCheck, faInfo, faEdit, faThumbsUp, faThumbsDown, faQuestion, faExclamationTriangle, faSadTear, faExclamationCircle, faBolt, faSyncAlt, faFilePdf, faPrint, faChartBar, faFlagCheckered, faAngleUp, faAngleDown, faUpload, faComment, faEye, faHistory, faExclamation, faAngleDoubleDown, faTimesCircle, faSearch, faExternalLinkAlt, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import './registerServiceWorker'

void (async function () {
  Vue.config.productionTip = false

  apiService.init(process.env.VUE_APP_API_URL)

  localforage.config({ name: process.env.VUE_APP_LOCALFORAGE_NAME || 'topqclient' })

  require('dayjs/locale/en')
  dayjs.locale('en')
  var advancedFormat = require('dayjs/plugin/advancedFormat')
  dayjs.extend(advancedFormat)
  Vue.prototype.$dayjs = dayjs

  // setup buefy with fontawesome
  const faIcons = [faTachometerAlt, faUser, faSignOutAlt, faTimes, faBars, faHome, faUserCircle, faArrowLeft, faArrowRight, faAngleLeft, faAngleRight, faArrowUp, faPlay, faCheck, faInfo, faEdit, faThumbsUp, faThumbsDown, faQuestion, faExclamationTriangle, faSadTear, faExclamationCircle, faBolt, faSyncAlt, faFilePdf, faPrint, faChartBar, faFlagCheckered, faAngleUp, faAngleDown, faUpload, faComment, faEye, faHistory, faExclamation, faAngleDoubleDown, faTimesCircle, faSearch, faExternalLinkAlt, faFileExcel]
  library.add(faIcons)
  Vue.component('vue-fontawesome', FontAwesomeIcon)

  // setup buefy
  const bComponents = [Table, Sidebar, Menu, Icon, Button, Modal, Dialog, Navbar, Pagination, Progress, Field, Select, Input, Checkbox, Radio, Tooltip, Collapse, Upload, Toast, Autocomplete, Dropdown]
  bComponents.forEach(x => { Vue.use(x) })
  ConfigProgrammatic.setOptions({
    defaultIconComponent: 'vue-fontawesome',
    defaultIconPack: 'fas'
  })

  mainAuth.startup().then(ok => {
    if (ok) {
      new Vue({
        router,
        store,
        render: h => h(App)
      }).$mount('#app')
    }
  })
})()
