import ApiService from '@/services/api.service'

// initial state
export const getDefaultState = () => {
  return {
    issueYear: null,
    topics: [],
    reqmts: [],
    criteria: [],
    audit: null,
    filter: 'all',
    current: 1
  }
}
const state = getDefaultState()

const getters = {
  issueYear () {
    return state.issueYear
  },
  topics (state) {
    return state.topics
  },
  reqmts (state) {
    return state.reqmts
  },
  criteria (state) {
    return state.criteria.sort((a, b) => a.tnr - b.tnr)
  },
  criterionById: (state) => (ci) => {
    return state.criteria.find(criterion => criterion.ci === ci)
  },
  rqmtById: (state) => (ri) => {
    return state.reqmts.find(reqmt => reqmt.ri === ri)
  },
  topicById: (state) => (tnr) => {
    return state.topics.find(topic => topic.tnr === tnr)
  }
}

const actions = {
  loadManual: async ({ state, commit }, year) => {
    if (!state.topics.length) {
      const response = await ApiService.get(`audits/manual/${year}`)
      commit('saveManual', response)
    }
  },
  async loadAudit ({ state, commit }, auditId) {
    const exists = state.audit && state.audit.id === auditId
    if (!exists) {
      const response = await ApiService.get(`audits/${auditId}`)
      commit('saveAudit', response.data)
    }
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  saveManual: (state, manual) => {
    state.issueYear = manual.issueYear
    state.topics = manual.topics
    state.reqmts = manual.requirements
    state.criteria = manual.criterias
  },
  saveAudit: (state, audit) => {
    state.audit = audit
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
