<template>
  <section class="box">
    <p class="is-size-5 mb-6">Du bist nun ausgeloggt</p>
    <b-button v-if="isOnline" type="is-primary" @click.prevent="login" :loading="loggingIn">
      Erneut anmelden
    </b-button>
    <div v-else class="has-text-danger has-text-weight-bold">Die Anmeldung ist nur mit Internetzugang möglich</div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      loggingIn: false
    }
  },
  computed: {
    ...mapGetters({
      isOnline: 'global/isOnline'
    })
  },
  methods: {
    login () {
      this.loggingIn = true
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
