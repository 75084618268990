// initial state
export const getDefaultState = () => {
  return {
    activeAudit: null,
    page: 1,
    perPage: 10,
    sortOrder: 'desc',
    sortField: 'year',
    filterString: '',
    criteriaFilter: 'all',
    current: 1
  }
}
const state = getDefaultState()

const getters = {
  activeAudit (state) {
    return state.activeAudit
  },
  page (state) {
    return state.page
  },
  perPage (state) {
    return state.perPage
  },
  sortOrder (state) {
    return state.sortOrder
  },
  sortField (state) {
    return state.sortField
  },
  filterString (state) {
    return state.filterString
  },
  tableUrlArgs: (state, getters, rootState) => {
    const args = [
      `page=${state.page}`,
      `perPage=${state.perPage}`,
      `sort=${state.sortField} ${state.sortOrder}`,
      `userId=${rootState.auth.impersonated ? rootState.auth.impersonated.sub : ''}`,
      state.filterString
    ].join('&')
    return `?${args.replace(' ', '%20').slice(0, -1)}`
  },
  current (state) {
    return state.current
  },
  criteriaFilter (state) {
    return state.criteriaFilter
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setActiveAudit (state, audit) {
    if (!state.activeAudit || state.activeAudit.id !== audit.id) {
      state.current = 1
      state.criteriaFilter = 'all'
    }
    state.activeAudit = audit
  },
  setAuditReportId (state, reportId) {
    state.activeAudit.reportId = reportId
  },
  setPage (state, page = 1) {
    state.page = page
  },
  setPerPage (state, perPage = 10) {
    state.perPage = perPage
  },
  setSortOrder (state, sortOrder = 'desc') {
    state.sortOrder = sortOrder
  },
  setSortField (state, sortField = 'year') {
    state.sortField = sortField
  },
  setFilterString (state, filterString = '') {
    state.filterString = filterString
  },
  updateLocalAuditCriteriaItems (state, newItems) {
    state.activeAudit.criteria = state.activeAudit.criteria.filter(c => !newItems.find(n => n.ci === c.ci))
    state.activeAudit.criteria.push(...newItems)
  },
  setCurrent (state, current) {
    state.current = current
  },
  setCriteriaFilter (state, criteriaFilter) {
    state.criteriaFilter = criteriaFilter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
