<template>
  <section class="box has-text-centered">
    <div class="mb-6">
      <h1 class="is-size-3 has-text-primary mb-4">
        TopQ - Das Qualitätsmanagementsystem von TopPharm
      </h1>
      <img src="@/assets/images/logos/LogoTopQ.png" alt="TopQ Logo">
    </div>
    <b-button v-if="isOnline" type="is-primary" @click.prevent="login" :loading="loggingIn">
      Anmelden
    </b-button>
    <div v-else class="has-text-danger has-text-weight-bold">Die Anmeldung ist nur mit Internetzugang möglich</div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      loggingIn: false
    }
  },
  mounted () {
    if (!this.$oidc.isAuthenticated) {
      this.$oidc.signIn().then((e) => {
        if (this.$oidc.isAuthenticated) {
          this.login()
        }
      })
    } else {
      this.login()
    }
  },
  computed: {
    ...mapGetters({
      isOnline: 'global/isOnline'
    })
  },
  methods: {
    login () {
      this.loggingIn = true
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
